<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <CardComponent
      :isScrollable="true"
      :title="cardTitle"
      icon="ballot"
      class="modal-card"
      style="width: 800px"
      headerIcon="close"
      hasFooterSlot
      @header-icon-click="$emit('close')"
    >
      <form>
        <b-field label="エリア" class="has-check" horizontal>
          <ValidationField rules="required">
            <b-checkbox
              v-model="programForm.areas"
              v-for="option in AREA_OPTIONS"
              :key="option.value"
              :native-value="option.value"
            >
              {{ option.label }}
            </b-checkbox>
          </ValidationField>
        </b-field>
        <b-field label="分類" horizontal>
          <b-field attached grouped>
            <ValidationField rules="required">
              <b-select v-model="programForm.parentGroupIdAlt">
                <option :value="null">未選択</option>
                <option
                  v-for="group in parentGroups"
                  :value="group.id"
                  :key="group.id"
                >
                  {{ group.name }}
                </option>
              </b-select>
            </ValidationField>

            <ValidationField rules="required">
              <b-select
                v-model="programForm.groupId"
                :disabled="!programForm.parentGroupId"
              >
                <option :value="null">未選択</option>
                <option
                  v-for="group in groupOptions"
                  :value="group.id"
                  :key="group.id"
                >
                  {{ group.name }}
                </option>
              </b-select>
            </ValidationField>
          </b-field>
        </b-field>
        <b-field label="名称" message="重複する名前は作成できません" horizontal>
          <ValidationInput
            v-model="programForm.name"
            rules="required|max:100"
            placeholder="必須"
          ></ValidationInput>
        </b-field>
        <b-field
          label="規定時間(分)"
          message="スケジュール作成時の初期値に利用されます"
          horizontal
        >
          <ValidationInput
            v-model="programForm.minutes"
            rules="integer|min_value:10"
            placeholder="任意"
          ></ValidationInput>
        </b-field>
        <b-field
          label="feeカテゴリ"
          message="インストラクタの時間給設定と紐づきます"
          class="has-check"
          horizontal
        >
          <ValidationField rules="required">
            <EnumPicker
              noneLabel="未選択"
              :members="CATEGORY_OPTIONS"
              :grouped="true"
              :grouped-multiline="true"
              v-model="programForm.category"
            />
          </ValidationField>
        </b-field>
        <b-field label="備考" horizontal>
          <b-input
            type="textarea"
            v-model="programForm.note"
            maxlength="500"
            :rows="2"
          />
        </b-field>
        <hr v-if="!isCreate" />
        <b-field
          label="ステータス"
          message="新規スケジュールへの設定可否"
          horizontal
          v-if="!isCreate"
        >
          <b-switch v-model="programForm.active"> </b-switch>
        </b-field>
      </form>
      <div slot="footer" class="buttons p-4">
        <b-button
          native-type="button"
          type="is-primary"
          :loading="isLoading"
          @click.prevent="handleSubmit(willSave)"
          >登録</b-button
        >
        <b-button
          type="is-primary is-outlined"
          @click="$emit('close')"
          :loading="isLoading"
          >キャンセル</b-button
        >
      </div>
    </CardComponent>
  </ValidationObserver>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { MProgram, ProgramForm } from '@/types/typeProgram.js'
import { ApiProgram } from '@/api'
import { Enums } from '@/types/enum'
import EnumPicker from '../EnumPicker.vue'
import CardComponent from '../CardComponent.vue'
export default {
  mixins: [BaseMixin],
  components: { EnumPicker, CardComponent },
  props: {
    mProgram: {
      type: MProgram,
      default: null
    },
    mProgramGroups: Array,
  },
  computed: {
    AREA_OPTIONS: () => Enums.Area.list,
    CATEGORY_OPTIONS: () => Enums.FeeCategory.list,
    cardTitle () {
      return this.mProgram ? `プログラム「${this.mProgram.name}」` : 'プログラム新規作成'
    },
    isCreate () {
      return !this.mProgram
    },
    parentGroups () {
      return this.mProgramGroups.filter(row => !row.parentId)
    },
    groupOptions () {
      if (!this.programForm.parentGroupId) {
        return []
      }
      return this.mProgramGroups.filter(row => row.parentId === this.programForm.parentGroupId)
    }
  },
  data () {
    return {
      programForm: new ProgramForm()
    }
  },
  methods: {
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        ApiProgram.saveProgram(this.programForm).then(({ mProgram }) => {
          this.$emit('saved', mProgram)
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
  },
  mounted () {
    if (this.mProgram) {
      this.programForm.fill(this.mProgram)
    }
  }
}
</script>
