<template>
  <article class="media">
    <figure class="media-left">
      <div class="content">
        <p class="image is-64x64">
          <img src="https://bulma.io/images/placeholders/128x128.png" />
        </p>
      </div>
    </figure>
    <div class="media-content">
      <div class="content">
        <p>
          <b-taglist attached v-if="mParentGroup" class="mb-0">
            <span class="tag is-info is-light">{{ mParentGroup.name }}</span>
            <span class="tag is-light" v-if="mProgramGroup">{{
              mProgramGroup.name
            }}</span>
            <span class="tag is-dark" v-if="!mProgram.active">利用不可</span>
          </b-taglist>
          <strong>{{ mProgram.name }}</strong>
          <small class="px-1">{{ mProgram.minutes || '-' }}分</small>/<small
            class="px-1"
            >{{ mProgram.category.label }}fee</small
          >
          <br />
          <small>{{ mProgram.note }}</small>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import { MProgram } from '@/types/typeProgram'
export default {
  props: {
    mProgram: MProgram,
    groupMap: Object
  },
  computed: {
    mParentGroup () {
      return this.mProgram.parentGroupId ? this.groupMap[this.mProgram.parentGroupId] : null
    },
    mProgramGroup () {
      return this.mProgram.groupId ? this.groupMap[this.mProgram.groupId] : null
    },
  }
}
</script>

<style>
</style>