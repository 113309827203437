<template>
  <div>
    <TitleBar :title-stack="titleStack" />
    <HeroBar>
      プログラム管理
      <div class="buttons" slot="right">
        <b-button
          icon-left="dock-window"
          type="is-info is-light"
          size="is-small"
          @click="$router.push({ name: 'ProgramGroups' })"
          >グループ管理へ</b-button
        >
        <b-button
          icon-left="plus-circle"
          type="is-info"
          size="is-small"
          @click="willCreate"
          >プログラム新規作成</b-button
        >
      </div>
    </HeroBar>
    <div class="is-relative" style="min-height: 100px" v-if="isLoading">
      <b-loading :active="isLoading" :is-full-page="false" />
    </div>
    <section class="section is-main-section" v-else>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="is-flex-direction-column">
              <b-field label="エリア" class="has-check">
                <b-radio
                  name="filter_area"
                  v-model="programFilter.areaVal"
                  :native-value="null"
                  >全て</b-radio
                >
                <b-radio
                  name="filter_area"
                  v-model="programFilter.areaVal"
                  v-for="option in AREA_OPTIONS"
                  :key="option.value"
                  :native-value="option.value"
                >
                  {{ option.label }}
                </b-radio>
              </b-field>
              <b-field
                label="大分類"
                class="has-check"
                v-if="parentGroups.length"
              >
                <div class="block" style="max-width: 800px">
                  <b-radio
                    name="filter_parent"
                    v-model="programFilter.parentGroupIdAlt"
                    :native-value="null"
                    >全て</b-radio
                  >
                  <b-radio
                    name="filter_parent"
                    v-model="programFilter.parentGroupIdAlt"
                    v-for="g in parentGroups"
                    :key="g.id"
                    :native-value="g.id"
                  >
                    {{ g.name }}
                  </b-radio>
                </div>
              </b-field>
              <b-field
                label="小分類"
                class="has-check"
                v-if="programFilter.parentGroupId"
              >
                <div class="block" style="max-width: 800px">
                  <b-radio
                    name="filter_group"
                    v-model="programFilter.groupId"
                    :native-value="null"
                    >全て</b-radio
                  >
                  <b-radio
                    name="filter_group"
                    v-model="programFilter.groupId"
                    v-for="g in childGroups"
                    :key="g.id"
                    :native-value="g.id"
                  >
                    {{ g.name }}
                  </b-radio>
                </div>
              </b-field>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="is-flex-direction-column">
              <b-field label="ステータス">
                <b-checkbox v-model="programFilter.showActive">有効</b-checkbox>
                <b-checkbox v-model="programFilter.showDeActive"
                  >利用不可</b-checkbox
                >
              </b-field>
              <b-field label="プログラム名" addons>
                <b-input
                  v-model="programFilter.text"
                  placeholder="あいまい検索"
                />
                <b-button type="is-light" @click="programFilter.text = ''"
                  ><b-icon icon="eraser" custom-size="default"
                /></b-button>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Tiles v-if="rows.length" :maxPerRow="4">
        <div class="tile is-child" key="summary">
          <CardWidget
            v-if="filtered.length === mPrograms.length"
            type="is-success"
            icon="bookmark-multiple-outline"
            :number="mPrograms.length"
            suffix="件"
            label="総件数"
          />
          <CardWidget
            v-else
            type="is-success"
            icon="filter"
            :number="filtered.length"
            :suffix="`/${mPrograms.length}件`"
            label="絞り込み件数"
          />
        </div>
        <div
          class="tile is-child box is-relative"
          v-for="mProgram in rows"
          :key="mProgram.id"
        >
          <ProgramMedia :mProgram="mProgram" :groupMap="groupMap" />
          <div
            class="buttons tile_buttons"
            style="position: absolute; left: 1rem; bottom: 1rem"
          >
            <b-button
              type="is-warning"
              icon-left="settings"
              size="is-small"
              @click="willEdit(mProgram)"
              >編集</b-button
            >
          </div>
        </div>
      </Tiles>

      <div class="content has-text-grey has-text-centered" v-else>
        <p>
          <b-icon icon="emoticon-sad" size="is-large" />
        </p>
        <p>Nothing's here&hellip;</p>
      </div>
      <div class="has-text-centered" v-if="shouldShowLoadMore">
        <b-button @click="loadMore += 1">更に表示</b-button>
      </div>
      <b-button @click="willScrollUp" type="is-warning" v-if="loadMore > 0"
        >上に戻る</b-button
      >
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import { ApiProgram } from '@/api'
import Tiles from '@/components/Tiles.vue'
import ProgramMedia from '@/components/Programs/ProgramMedia.vue'
import ProgramFormVue from '@/components/Programs/ProgramForm.vue'
import { Collection } from '@/util'
import CardWidget from '@/components/CardWidget.vue'
import { Enums } from '@/types/enum'
import { mapMutations, mapState } from 'vuex'
export default {
  mixins: [BaseMixin],
  components: { HeroBar, TitleBar, Tiles, ProgramMedia, CardWidget },
  computed: {
    titleStack () {
      return [
        'マスタ管理', 'プログラム'
      ]
    },
    ...mapState('storeProgram', ['programFilter']),
    AREA_OPTIONS: () => Enums.Area.list,
    parentGroups () {
      return this.mProgramGroups.filter(row => !row.parentId)
    },
    childGroups () {
      if (!this.programFilter.parentGroupId) {
        return []
      }
      return this.mProgramGroups.filter(row => row.parentId === this.programFilter.parentGroupId)
    },
    groupMap () {
      return this.mProgramGroups.reduce((map, row) => {
        map[row.id] = row
        return map
      }, {})
    },
    filtered () {
      if (!this.programFilter) {
        return this.mPrograms
      }
      return this.mPrograms.filter(row => this.programFilter.test(row))
    },
    displayCount () {
      return 25 * (this.loadMore + 1) - 1
    },
    rows () {
      return this.filtered.slice(0, this.displayCount)
    },
    shouldShowLoadMore () {
      return this.displayCount < this.filtered.length
    }
  },
  data () {
    return {
      checkboxGroup: [],
      mPrograms: [],
      mProgramGroups: [],
      loadMore: 0,
    }
  },
  methods: {
    ...mapMutations('storeProgram', {
      resetStoreDataState: 'resetDataState',
    }),
    willCreate () {
      console.log('willCreate')
      this.$buefy.modal.open({
        parent: this,
        component: ProgramFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { mProgramGroups: this.mProgramGroups },
        events: {
          saved: this.didSaved,
        }
      })
    },
    willScrollUp () {
      window.scrollTo(0, 0)
      this.loadMore = 0
    },
    willEdit (mProgram) {
      console.log(mProgram, 'willEdit')
      this.$buefy.modal.open({
        parent: this,
        component: ProgramFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { mProgram, mProgramGroups: this.mProgramGroups },
        events: {
          saved: this.didSaved,
        }
      })
    },
    didSaved (mProgram) {
      console.log(mProgram, 'didSaved')
      this.mPrograms = new Collection(this.mPrograms).set(mProgram).sort('id', 'desc').members
      this.resetStoreDataState() // 他画面用のストアキャッシュをクリア
      this.alertSuccess('プログラム情報を更新しました')
    },
  },
  watch: {
    filtered () {
      this.loadMore = 0
    }
  },
  mounted () {
    this.isLoading = true
    setTimeout(() => {
      ApiProgram.fetchPrograms().then(({ mPrograms, mProgramGroups }) => {
        this.mPrograms = new Collection(mPrograms).sort('id', 'desc').members
        this.mProgramGroups = mProgramGroups
        this.isLoading = false
      }).catch(this.handleError)
    }, 1000)
  }
}
</script>

<style>
.tile_buttons {
  opacity: 0;
  transition-property: 'opacity';
  transition-duration: 0.3s;
  transition-delay: 0.1s;
}
.tile.is-child:hover .tile_buttons {
  opacity: 1;
}
</style>
